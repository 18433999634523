// typography
@mixin H1 {
  font-family: 'Fanta Pop', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  text-transform: uppercase;
}

@mixin H2 {
  font-family: 'Fanta Pop', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  text-transform: uppercase;
}

@mixin H3 {
  font-family: 'Fanta Pop', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 90%;
  text-transform: uppercase;
}

@mixin bodyCopySmall {
  font-family: 'Fanta Sans Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

// colors
$green: #befdb7;
$black: #000000;
$white: #ffffff;
$grey: #2f2f2f;
