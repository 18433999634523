@font-face {
	font-family: "Fanta Pop";
	src: url("./fonts/FantaPop-Condensed.ttf") format("truetype");
}

@font-face {
	font-family: "Fanta Sans Bold";
	src: url("./fonts/FantaSans-Bold.ttf") format("truetype");
	font-weight: bold;
}

@font-face {
	font-family: "Fanta Sans Regular";
	src: url("./fonts/FantaSans-Regular.ttf") format("truetype");
	font-weight: normal;
}

body {
	margin: 0;
	font-family: "Fanta Pop", "Fanta Sans Bold",
		"Fanta Sans Regular" -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  background: #141414;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
