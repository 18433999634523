@import '../theme.scss';

.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $black;
  color: $green;

  .loading-text {
    @include H3;
    font-size: 50px;
  }
}
